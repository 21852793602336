<template>
  <div class="container">
    <b-card-text>
      <div class="row">
        <div class="col-12 col-lg-6 mb-1">
          <label for="envetdate">Event date</label>
          <b-form-datepicker
            id="envetdate"
            v-model="info.event_date"
            class="mb-1"
          />
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <label for="foodlist">Food Item Name</label>
          <input
            class="form-control"
            placeholder="Food Item Name"
            list="food-list"
            type="foodlist"
            v-model="info.item"
            required
          />
          <datalist id="food-list">
            <option value="Egg Khichuri"></option>
            <option value="Chicken Khichuri"></option>
            <option value="Chicken Tehari"></option>
            <option value="Mutton Tehari"></option>
            <option value="Plain Rice with Vegetables"></option>
          </datalist>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-12 mb-1 input-group input-group-merge">
          <div class="input-group-prepend">
            <div class="input-group-text">Total Meal</div>
          </div>
          <input
            class="form-control"
            placeholder="0"
            type="number"
            v-model="info.total_packet"
          />
          <div class="input-group-append">
            <div class="input-group-text">Packet</div>
          </div>
        </div>
      </div>
      <hr />
    </b-card-text>
    <!-- start multiple field  -->
    <div>
      <div>
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in info.items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- Item Name -->
            <b-col md="4">
              <b-form-group
                label="Distribution Area"
                :label-for="'area' + index"
              >
                <b-form-input
                  :id="'area' + index"
                  type="text"
                  list="area-lists"
                  placeholder="Distribution area Name"
                  v-model="info.items[index].area"
                />
                <datalist id="area-lists">
                  <option value="Kamalapur, Valo Kajer Hotel"></option>
                </datalist>
              </b-form-group>
            </b-col>

            <!-- Cost -->
            <!-- <b-col md="2">
              <b-form-group label="Cost" label-for="cost">
                <b-form-input id="cost" type="number" placeholder="32" />
              </b-form-group>
            </b-col> -->

            <!-- Quantity -->
            <b-col md="2">
              <b-form-group label="Meal" :label-for="'quantity' + index">
                <b-form-input
                  :id="'quantity' + index"
                  type="number"
                  placeholder="0"
                  v-model="info.items[index].packet"
                />
              </b-form-group>
            </b-col>

            <!-- Profession -->
            <!-- <b-col lg="2" md="1">
              <b-form-group label="Price" label-for="price">
                <b-form-input id="pzrice" value="32$" plaintext />
              </b-form-group>
            </b-col> -->

            <!-- Remove Button -->
            <b-col lg="2" md="3" class="mb-50">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon icon="XIcon" class="mr-25" />
                <span>Delete</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>
        </b-form>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Add New Area</span>
      </b-button>
    </div>
    <div class="text-center mt-5">
      <b-button variant="success" @click="postdata">
        <feather-icon icon="SendIcon" class="mr-25" />
        <span>Submit</span>
      </b-button>
    </div>
    <div class="container mt-5 text-center" style="overflow-x: auto">
      <table border="0">
        <tbody>
          <tr>
            <th>Event Date</th>
            <th>Food Item</th>
            <th>Total Meal</th>
            <th>Crated by</th>
          </tr>
          <tr>
            <td>{{ res.event_date || "N/A" }}</td>
            <td>{{ res.item || "N/A" }}</td>
            <td>{{ res.total_packet || "N/A" }}</td>
            <td>{{ res.created_by || "N/A" }}</td>
          </tr>
        </tbody>
        <tbody v-if="res.area">
          <tr>
            <th>Distribution Area</th>
            <th>Meal</th>
          </tr>
          <tr v-for="(data, index) in res.area" :key="index">
            <td>{{ data.area }}</td>
            <td>{{ data.packet }}</td>
            <td>--</td>
            <td>--</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import {
  BTable,
  BProgress,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
  BFormDatepicker,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSpinbutton,
  BFormCheckbox,
  BFormSelect,
  BAlert,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { heightFade } from "@core/directives/animations";
import { heightTransition } from "@core/mixins/ui/transition";
import vSelect from "vue-select";
import axios from "axios";

export default {
  name: "addfood",
  components: {
    BTable,
    BProgress,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormDatepicker,
    vSelect,
    // PersonalDesh,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSpinbutton,
    BFormCheckbox,
    BFormSelect,
    BAlert,
    BOverlay,
  },
  directives: {
    "height-fade": heightFade,
    "b-modal": VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      res: "",
      info: {
        info_id: "new",
        event_date: null,
        item: "",
        total_packet: "",
        items: [{ id: 1 }],
      },
      nextTodoId: 2,
    };
  },
  computed: {},
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
    this.getlust();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    getlust() {
      // this.loder = true;
      this.$http
        .post(`/v1/fms/foodmanagement/lust/`)
        .then((response) => {
          this.res = response.data[0];

          // this.loder = false;
        })
        .catch((error) => {
          console.log(error);

          // this.loder = false;
        });
    },
    reset() {
      this.getlust();
      this.info = {
        info_id: "new",
        event_date: "",
        item: "",
        total_packet: "",
        items: [{ id: 1 }],
      };
    },
    postdata() {
      const that = this;
      var userdata = JSON.parse(localStorage.getItem("userData"));
      that.info.created_by = userdata.username;
      if (
        that.info &&
        that.info.event_date &&
        that.info.total_packet &&
        that.info.item
      ) {
        if (that.info.items) {
          this.$swal({
            title: "Are you sure?",
            text: "Yes, Submit this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, submit it!",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
            showLoaderOnConfirm: true,
            preConfirm() {
              that.$http
                .post(`/v1/fms/foodmanagement/post/`, that.info)
                .then((response) => {
                  console.log(response);
                  that.result = response.data;
                  that.gettran();
                })
                .catch((error) => {
                  // this.$swal.showValidationMessage(`Request failed:  ${error}`);
                  console.log(error);
                });
            },
          }).then((result) => {
            if (result.value) {
              this.$swal({
                icon: "success",
                title: "Created!",
                text: "Data has been Created.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              that.reset();
            }
          });
        } else {
          let variant = "danger";
          this.$bvToast.toast("Area is Required", {
            title: `Error`,
            variant,
            solid: true,
          });
        }
      } else {
        let variant = "danger";
        this.$bvToast.toast("All Fields is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
    repeateAgain() {
      this.info.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.info.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>
<style>
table,
th,
td {
  border: 1px solid rgb(0, 204, 255);
}
th,
td {
  padding: 15px;
  text-align: left;
}
/* th {
  background-color: rgb(0, 204, 255);
  color: white;
} */
table {
  width: 100%;
  border-collapse: collapse;
}
</style>
